@import 'typography';
@import 'vars';
@import 'colors';
@import 'card';
@import 'table';

body {
    margin: 0;
    background-color: var(--gray-titanium);
}
