:root {
    // Blue
    --blue-nitro: #1550c1;
    --blue-argon: #6698f8;
    --blue-helium: #dde8fe;
    --blue-iridium: #00033b;
    // Green
    --green-neon: #95f0d5;
    --green-radium: #14856a;
    // Yellow
    --yellow: #ffce26;
    // Red
    --red-oxygen: #d41d34;
    // Black
    --black-carbon: #1c2125;
    // Gray
    --gray-vanadium: #3d4d5a;
    --gray-tin: #6c757e;
    --gray-mercury: #a8b2bd;
    --gray-titanium: #dde5ec;
    --gray-scandium: #f4f6f8;
    // White
    --white: #fff;
    // Gradients
    --nitro-gas: linear-gradient(rgba(29, 32, 103, 1) 2%, rgba(49, 108, 221, 1) 52%, rgba(102, 152, 248, 1) 97%);
    --neon-gas: linear-gradient(rgba(51, 195, 150, 1) 0%, rgba(224, 255, 246, 1) 100%);
}
