.fire-card {
    box-shadow: var(--fire-card-shadow);
    border-radius: var(--fire-card-border-radius);

    background-color: #fff;
    padding: 25px;
    margin: 15px;

    &.transparent {
        box-shadow: none;
        background-color: transparent;
        padding: 0;
    }
}
