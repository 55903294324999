.table-container {
    border: 1px solid var(--gray-titanium);
    border-radius: 4px;
    margin: 10px 0;
}

.table {
    margin: auto;
    border-collapse: collapse;
    text-align: left;
    width: 100%;

    tbody tr:last-child {
        border-bottom: none;
    }

    tr {
        border-bottom: 1px solid var(--gray-titanium);
        height: 35px;

        td,
        th {
            padding: 8px 16px;
            font-weight: 500;
        }

        th {
            background-color: var(--gray-scandium);
        }

        td {
            &.clickable {
                color: var(--blue-nitro);
                cursor: pointer;
            }
        }

        &:hover {
            td {
                background-color: var(--gray-scandium);
            }
        }
    }
}
