html {
    font-size: 90%;
}

* {
    font-family: 'Inter', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 500;
}

a,
a:visited,
a:focus,
a.no-decoration,
a.no-decoration:hover,
.text {
    display: inline-block;
    color: #000;
    text-decoration: none;
    padding: 5px;
}

.link {
    text-decoration: underline;
    color: #0366d6;
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
}

a:hover {
    color: #0366d6;
    //text-decoration: underline;
}
